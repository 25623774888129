<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">
    <v-row no-gutters v-if="pointProperties">
      <v-col cols="12">
        <v-row no-gutters>

          <!-- Topbar -->
          <v-col cols="12">
            <v-row justify="space-between" no-gutters>
              <v-col cols="2">
                <v-btn
                  @click="getNextStops(true)"
                  icon
                  color="primary"
                  style="width: 30px !important; margin-top: -7px !important;">
                  <v-icon small>fas fa-redo</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="8">
                <h3 class="font-weight-bold text-center">{{pointProperties.name}}</h3>
              </v-col>
              <v-col cols="2">
                <div class="float-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on, attrs}">
                      <!--div>
                        <span v-if="pointProperties.distance < 1000">{{Math.round(pointProperties.distance).toFixed(0)}}m</span>
                        <span v-else>{{roundNumberV1(pointProperties.distance / 1000, 2)}}km</span>
                      </div-->
                      {{formatDistance(pointProperties)}}
                    </template>

                    <span>({{aroundDistance}})</span>
                  </v-tooltip>
                </div>
              </v-col>

            </v-row>
          </v-col>

          <!-- Modes -->
          <v-col cols="12" class="mt-12">
            <v-row justify="center">
              <template v-for="mode_ in Array.from(extractedModes)">
                <v-btn icon class="mx-3">
                  <IconModePointDetails
                    @toggle="toggleMode(mode_)"
                    :mode="findMode(mode_)"
                    :enabled="currentMode === mode_"
                  />
                </v-btn>
              </template>
            </v-row>
          </v-col>

          <!-- Lines -->
          <v-col cols="12" class="mt-12">
            <v-row class="custom-scrollbar" :justify="Object.keys(extractedLines).length > 7 ? 'start' : 'center'" no-gutters style="overflow-x: auto !important; flex-wrap: nowrap !important; height: 70px !important;">
              <template v-for="line_ of Object.keys(extractedLines)">
                <v-col cols="auto" class="my-2 mx-3">
                  <v-btn icon @click="toggleLine(line_)">
                    <v-avatar
                      :color="`#${currentLines.includes(line_) ? extractedLines[line_].bgColor : 'a3a3a3'}`"
                      class="font-weight-bold"
                      :style="`color: #${extractedLines[line_].textColor}`"
                      size="50">
                      <span style="font-size: 1.2rem !important;">{{extractedLines[line_].label}}</span>
                    </v-avatar>
                  </v-btn>
                  </v-col>
              </template>
            </v-row>
          </v-col>

          <!-- Stops 1 -->
          <v-col cols="12" class="mt-6">
            <v-card style="border-radius: 15px !important;">

              <!-- isLoaded and extractStops not empty, isExpanded -->
              <v-list
                v-if="isLoadedStops && extractedStopsMaxi.length > 0 && isStopsListExpanded">
                <template v-for="(stop_) of extractedStopsMaxi">
                  <v-list-item>
                    <v-row no-gutters align="center" justify="space-between">

                      <v-col cols="2">
                        <v-avatar
                          :color="`#${stop_.route.line.color}`"
                          :style="`color: #${stop_.route.line.text_color}`"
                          class="font-weight-bold"
                          size="35">
                          {{stop_.route.line.code}}
                        </v-avatar>
                      </v-col>


                      <v-col cols="8" class="overflow-hidden">
                        {{stop_.display_informations.direction}}
                      </v-col>

                      <v-col cols="2" class="font-weight-bold text-end">
                        {{formatTime(stop_.stop_date_time.arrival_date_time)}}
                      </v-col>
                    </v-row>
                  </v-list-item>
                </template>
              </v-list>

              <!-- Is NOT Expanded -->
              <v-list
                v-if="isLoadedStops && extractedStopsMini.length > 0 && !isStopsListExpanded">
                <template v-for="(stop_) of extractedStopsMini">
                  <v-list-item>
                    <v-row no-gutters align="center" justify="space-between">

                      <v-col cols="2">
                        <v-avatar
                          :color="`#${stop_.route.line.color}`"
                          :style="`color: #${stop_.route.line.text_color}`"
                          class="font-weight-bold"
                          size="35">
                          {{stop_.route.line.code}}
                        </v-avatar>
                      </v-col>


                      <v-col cols="8" class="overflow-hidden">
                        {{stop_.display_informations.direction}}
                      </v-col>

                      <v-col cols="2" class="font-weight-bold text-end">
                        {{formatTime(stop_.stop_date_time.arrival_date_time)}}
                      </v-col>
                    </v-row>
                  </v-list-item>
                </template>
              </v-list>
              <!-- Loading... -->
              <Skeleton v-else-if="!isLoadedStops" type="list-item" :occurrence="5" />

              <!-- No result -->
              <v-list-item v-else-if="extractedStopsMini.length === 0 && extractedStops2Mini.length === 0" class="text-center">{{$t('view.point-details.public-no-result')}}</v-list-item>

              <div
                v-if="isLoadedStops && extractedStops.length > 0"
                class="text-center ma-0 pa-0">
                <v-btn icon @click="isStopsListExpanded = !isStopsListExpanded">
                  <v-icon v-if="isStopsListExpanded" color="primary">far fa-chevron-up</v-icon>
                  <v-icon v-else color="primary">far fa-chevron-down</v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-col>

          <!-- Stops 2 -->
          <v-col cols="12" class="mt-6" v-if="extractedStops2.length > 0">
            <v-card style="border-radius: 15px !important;">

              <!-- isLoaded and extractStops not empty -->
              <v-list
                v-if="isLoadedStops && extractedStops2Maxi.length > 0 && isStopsListExpanded2">
                <template v-for="stop_ of extractedStops2Maxi">
                  <v-list-item>
                    <v-row no-gutters align="center" justify="space-between">

                      <v-col cols="2">
                        <v-avatar
                          :color="`#${stop_.route.line.color}`"
                          :style="`color: #${stop_.route.line.text_color}`"
                          class="font-weight-bold"
                          size="35">
                          {{stop_.route.line.code}}
                        </v-avatar>
                      </v-col>


                      <v-col cols="8" class="overflow-hidden">
                        {{stop_.display_informations.direction}}
                      </v-col>

                      <v-col cols="2" class="font-weight-bold text-end">
                        {{formatTime(stop_.stop_date_time.arrival_date_time)}}
                      </v-col>
                    </v-row>
                  </v-list-item>
                </template>
              </v-list>

              <v-list
                v-if="isLoadedStops && extractedStops2Mini.length > 0 && !isStopsListExpanded2">
                <template v-for="stop_ of extractedStops2Mini">
                  <v-list-item>
                    <v-row no-gutters align="center" justify="space-between">

                      <v-col cols="2">
                        <v-avatar
                          :color="`#${stop_.route.line.color}`"
                          :style="`color: #${stop_.route.line.text_color}`"
                          class="font-weight-bold"
                          size="35">
                          {{stop_.route.line.code}}
                        </v-avatar>
                      </v-col>


                      <v-col cols="8" class="overflow-hidden">
                        {{stop_.display_informations.direction}}
                      </v-col>

                      <v-col cols="2" class="font-weight-bold text-end">
                        {{formatTime(stop_.stop_date_time.arrival_date_time)}}
                      </v-col>
                    </v-row>
                  </v-list-item>
                </template>
              </v-list>

              <!-- Loading... -->
              <Skeleton v-else-if="!isLoadedStops" type="list-item" :occurrence="5" />

              <!-- No result --
              <v-list-item v-else-if="extractedStops2Mini.length === 0" class="text-center">{{$t('view.point-details.public-no-result')}}</v-list-item-->

              <div
                v-if="isLoadedStops && extractedStops2.length > 0"
                class="text-center ma-0 pa-0">
                <v-btn icon @click="isStopsListExpanded2 = !isStopsListExpanded2">
                  <v-icon v-if="isStopsListExpanded2" color="primary">far fa-chevron-up</v-icon>
                  <v-icon v-else color="primary">far fa-chevron-down</v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-col>

          <!-- Bike station -->
          <v-col cols="12" class="mt-4">
            <v-card style="border-radius: 15px !important;" class="w-100">
              <v-row no-gutters v-if="bikeStation">

                <v-col cols="12">
                  <v-row class="justify-space-between ma-3" no-gutters>
                    <div>
                      <IconModeSimple :mode="modes.find((el) => el.name === 'BIKE_STATION')" />
                    </div>
                    <div class="font-weight-bold">
                      <v-row no-gutters align="center">
                        <v-col cols="12"  class="mb-1">
                          <div class="text-center">Station {{bikeStation.properties.name}}</div>
                        </v-col>
                        <v-col cols="12">
                          <v-img v-if="getBrandLogo(bikeStation.properties)" :src="getBrandLogo(bikeStation.properties)" width="40" style="margin: 0 auto !important;"/>
                        </v-col>
                      </v-row>
                    </div>
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs}">
                          <!--div>
                            <span v-if="bikeStation.properties.distance < 1000">{{Math.round(bikeStation.properties.distance).toFixed(0)}}m</span>
                            <span v-else>{{roundNumberV1(bikeStation.properties.distance / 1000, 2)}}km</span>
                          </div-->
                          <div>
                            {{formatDistance({lng: bikeStation.geometry.coordinates[0], lat: bikeStation.geometry.coordinates[1]})}}
                          </div>
                        </template>

                        <span>({{aroundDistance(bikeStation.properties.distance)}})</span>
                      </v-tooltip>
                    </div>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-4 mb-2">
                  <v-row no-gutters justify="space-between" class="mx-4 font-weight-bold">
                    <p v-if="bikeStation.properties.availableVehicles && bikeStation.properties.availableVehicles !== 'null'">Vélos : {{bikeStation.properties.availableVehicles}}</p>
                    <p v-if="bikeStation.properties.availableStands && bikeStation.properties.availableStands !== 'null'">Places : {{bikeStation.properties.availableStands}}</p>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters v-else-if="!isLoadedStation">
                <Skeleton type="list-item-avatar-three-line" :occurrence="1" />
              </v-row>
            </v-card>
          </v-col>

          <!-- Go btn -->
          <v-col cols="12" class="my-4 text-center">
            <v-btn
              @click="goTo"
              color="primary"
              width="150"
              style="border-radius: 10px !important;"
              class="font-weight-bold" >GO
            </v-btn>
          </v-col>

          <!-- Link to micro mobility -->
          <v-col cols="12" class="text-center my-4">
            <div class="my-2 font-weight-bold">Vous ne trouvez rien ? Essayez la micro mobilité </div>
            <v-btn
              @click="$router.push({name: 'PointDetailsMicroMobility', query: {coords: `${point.geometry.coordinates[1]},${point.geometry.coordinates[0]}`}})"
              color="primary"
              class="font-weight-bold"
              style="border-radius: 10px !important;">Micro-mobilité
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import qs from "qs";
import pointDetails from "@/mixins/pointDetails";
import formatter from "@/mixins/formatter";

import {DateTime} from "luxon";
export default {
  name: "PointDetailsPublicTransports",

  data() {
    return {
      stop_id: "",
      isLoadedPoint: false,
      isLoadedStops: false,
      isLoadedStation: false,
      extractedModes: new Set(),
      currentMode: null,
      extractedLines: {},
      currentLines: [],
      extractedStops: [],
      extractedStops2: [],
      bikeStation: null,
      isStopsListExpanded: false,
      isStopsListExpanded2: false,
      currentTimezone: "Europe/Paris"
    }
  },

  components: {
    IconModePointDetails: () => import("@/components/Common/IconModePointDetails"),
    IconModeSimple: () => import("@/components/Common/IconModeSimple"),
    Skeleton: () => import("@/components/Common/Skeleton"),
  },

  mixins: [pointDetails],

  mounted() {
    this.stop_id = this.$route.params.id && this.$route.params.id.startsWith("stop") ? this.$route.params.id : null;
    if (this.stop_id == null)
      return this.$router.replace({name: "not_found"});
    //console.log(this.point);
    if (!!this.point && this.point.properties.id === this.stop_id) {
      this.isLoadedPoint = true;
      this.getNextStops();
    }
    else {
      this.getPlaceDetails();

    }
  },

  methods: {
    getPlaceDetails() {
      this.isLoadedPoint = false;
      this.$http
        .get(`/map/viewer/public-transports-place?coords=${this.coords.lat},${this.coords.lng}&stationID=${this.stop_id}`, {
          headers: this.$session.exists() ? {
            Authorization: `Bearer ${this.$session.get('jwt')}`
          } : {}
        })
        .then((res) => {
          console.log(res.data);
          if (res.data && res.data.station) {
            this.point = {
              ...res.data.station,
              properties: {...res.data.station.properties, physical_mode: this.currentMode}
            };
            this.$store.commit("modes/setCurrentMarker", {lng: res.data.station.geometry.coordinates[0], lat: res.data.station.geometry.coordinates[1]});

            this.$store.dispatch("modes/getTransportsAround", {
              modes: this.$store.getters["modes/getEnabled"],
              keepOldData: false,
              jwt: this.$session.get('jwt'),
              messages: {
                ERROR_MODE_NOT_LOADED: this.$t('viewer.errors.MODES_NOT_LOADED')
              }
            });

            this.getNextStops();
          }
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
          this.$router.replace({name: "Home"});
        })
        .finally(() => {
          this.isLoadedStops = true;
        })
    },

    getNextStops(isRefresh = false) {
      this.isLoadedStops = false;
      this.$http
        .get(`/map/viewer/next-stops?coords=${this.coords.lat},${this.coords.lng}&stationID=${this.stop_id}`, {
          headers: this.$session.exists() ? {
            Authorization: `Bearer ${this.$session.get('jwt')}`
          } : {}
        })
        .then((res) => {
          this.currentTimezone = res.data.context.timezone;
          this.nextStops = res.data.departures;
          this.extractModes(isRefresh);
          this.getBikeStation();
          //this.$store.commit("setCoordinates", {lng: this.point.geometry.coordinates[0], lat: this.point.geometry.coordinates[1]});
        })
        .catch(err => {
          console.log(err);
          this.$store.commit("alert/showErrorAxios", err);
          this.$router.replace({name: "Home"});
        })
        .finally(() => {
          this.isLoadedStops = true;
        })
    },

    getBikeStation() {
      this.isLoadedStation = true;
      this.$http
        .get(`/map/viewer/modes?coords=${this.point.geometry.coordinates[1]},${this.point.geometry.coordinates[0]}`, {
          params: {
            modes: ["BIKE_STATION"]
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, {arrayFormat: "brackets"})
          },
          headers: this.$session.get('jwt') ? {Authorization: "Bearer " + this.$session.get('jwt')} : {}
        })
      .then((res) => {
        if (res.data && res.data.length > 0)
          this.bikeStation = res.data[0];
      })
      .catch(err => {
        //this.$store.commit("alert/showError", this.$t('view'));
      })
      .finally(() => {
        this.isLoadedStation = true;
      })
    },

    goTo() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.$router.push({
          name: 'Itinerary',
          query: {
            to: `${this.pointProperties.lat},${this.pointProperties.lng}`,
            toLabel: this.pointProperties.name,
            fromLabel: "Ma position",
            from: `${position.coords.latitude},${position.coords.longitude}`
          }
        });
      }, (error) => {
          this.$router.push({
            name: 'Itinerary',
            query: {
              to: `${this.pointProperties.lat},${this.pointProperties.lng}`,
              toLabel: this.pointProperties.name,
            }
          });
      });
    },

    redirect(link) {
      window.open(link);
    },

    formatTime(datetime) {
      if (datetime) {
        const diff = DateTime.fromISO(datetime, {zone: this.currentTimezone}).diff(DateTime.now().setZone(this.currentTimezone), ["minutes"]);

        if (diff.minutes > 60 || diff.minutes < -5)
          return DateTime.fromISO(datetime, {zone: this.currentTimezone}).toFormat("HH:mm");
        else if (diff.minutes <= 1)
          return "A quai";

        return `${parseInt(diff.minutes)}min`;
      }

      return "";
    },

    extractModes(isRefresh = false) {
      for (const stop of this.nextStops) {
        this.extractedModes.add(stop.display_informations.physical_mode);
      }

      if (this.extractedModes.size > 0) {

        // Has mode in query
        if (!isRefresh && this.$route.query.mode) {
          const mode = this.findMode(this.$route.query.mode);

          // Mode is detected
          if (mode) {
            for (let extractedMode of Array.from(this.extractedModes)) {
              if (mode.matching.includes(extractedMode)) {
                this.currentMode = extractedMode;
                this.extractLines(isRefresh);
                return;
              }
            }
          }
        }
      }

      if (!isRefresh)
        this.currentMode = this.extractedModes.values().next().value;

      this.extractLines(isRefresh);
    },

    extractLines(isRefresh = false) {
      if (this.currentMode) {

        // Init
        this.extractedLines = {};

        for (const stop of this.nextStops) {
          if (stop.display_informations.physical_mode === this.currentMode)
            this.extractedLines[stop.route.line.code] = {label: stop.route.line.code, bgColor: stop.route.line.color, textColor: stop.route.line.text_color};
        }

        if (isRefresh) {
          if (this.currentLines.length === 1 && Object.keys(this.extractedLines).find(el => String(el) === this.currentLines[0])) {
            this.extractStops();
            return;
          }
        }

        this.currentLines = [];
        for (const line_ in this.extractedLines) {
          this.currentLines.push(line_);
        }

        //console.log(this.currentLines);
        this.extractStops();
      }
    },

    extractStops() {
      if (this.currentMode && this.currentLines.length > 0) {

        // Init
        this.extractedStops = [];
        this.extractedStops2 = [];

        for (const stop of this.nextStops) {
          if (stop.display_informations.physical_mode === this.currentMode &&
            this.currentLines.includes(stop.display_informations.code)) {
            //if (this.currentLines.length > 1 || (this.extractedStops.length === 0 || this.extractedStops.some((el) => el.display_informations.direction === stop.display_informations.direction))) {
            if (['forward', 'outbound'].includes(stop.route.direction_type) || this.currentMode === "TRAIN" /*|| this.currentMode === "RER"*/ || this.currentLines.length > 1 /*|| this.extractedStops.length === 0*/) {
              if (this.extractedStops.length < 15)
                this.extractedStops.push(stop);
            } else {
              if (this.extractedStops2.length < 15)
                this.extractedStops2.push(stop);
            }
          }
        }
        //console.log(this.extractedStops);
      }
    },

    findMode(mode_) {
      return this.modes.find((el) => {
        return el.name === mode_ || el.matching.includes(mode_)
      });
    },

    toggleMode(mode) {
      if (this.extractedModes.size > 1) {
        this.currentMode = mode;
        this.extractLines();
      }
      else if (this.extractedModes.size === 1) {
        this.extractLines();
      }
    },

    toggleLine(line) {

      this.currentLines = [line];
      this.extractStops();
    }
  },

  computed: {

    coords() {
      return this.$store.getters["coords"];
    },

    point: {
      get() {
        if (this.$store.getters['modes/getCurrentPoint']) {
          return this.$store.getters['modes/getCurrentPoint'];
        }

        return this.$store.getters["modes/getCurrentPoint"];
      },

      set(val) {
        this.$store.commit("modes/setCurrentPoint", val);
      }
    },
    pointProperties() {
      if (this.$store.getters['modes/getCurrentPoint']) {
        return this.$store.getters['modes/getCurrentPoint'].properties;
      }

      return this.$store.getters["modes/getCurrentPoint"];
    },

    extractedStopsMini() {
      return this.extractedStops.slice(0, 3);
    },

    extractedStopsMaxi() {
      return this.extractedStops.slice(0, 10);
    },

    extractedStops2Mini() {
      return this.extractedStops2.slice(0, 3);
    },

    extractedStops2Maxi() {
      return this.extractedStops2.slice(0, 10);
    }
  },

  watch: {
    '$route.query.mode': function(newValue, oldValue) {
      if (this.$route.params.id === this.stop_id && newValue !== oldValue)
        this.extractModes();
    }
  }
}
</script>

<style scoped>
.v-list__tile{
  height: 40px !important;
}
</style>